import axios from 'axios';
import {loadStateWithKey} from './LocalStorage';

function getBaseUrl() {
  // get basic url for endpoints
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return 'https://dev.iapi.admin.onsitedemolition.ca';
  }
  return 'https://iapi.admin.onsitedemolition.ca';
}

axios.defaults.baseURL = getBaseUrl();

function getConfig() {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': loadStateWithKey('X-Api-Key'),
    },
  };
}

function getFileUploadConfig() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Api-Key': loadStateWithKey('X-Api-Key'),
    },
  };
}

export function fnGetClients(data = {}) {
  return axios.post('/clients', data, getConfig());
}

export function fnAddClient(data = {}) {
  return axios.post('/clients/new', data, getConfig());
}

export function fnEditClient(clientID, data = {}) {
  return axios.post(`/clients/${clientID}/edit`, data, getConfig());
}

export function fnGetClientLogs(clientID, data = {}) {
  return axios.post(`/clients/${clientID}/logs`, data, getConfig());
}

export function fnGetEstimates(data = {}) {
  return axios.post('/estimates', data, getConfig());
}

export function fnAddEstimate(data = {}) {
  return axios.post('/estimates/new', data, getConfig());
}

export function fnEditEstimate(estimateID, data = {}) {
  return axios.post(`/estimates/${estimateID}/edit`, data, getConfig());
}

export function fnGetEstimateNotes(estimateID, data = {}) {
  return axios.post(`/estimates/${estimateID}/notes`, data, getConfig());
}

export function fnAddEstimateNote(estimateID, data = {}) {
  return axios.post(`/estimates/${estimateID}/notes/new`, data, getConfig());
}

export function fnGetExpenses(data = {}) {
  return axios.post('/expenses', data, getConfig());
}

export function fnAddExpense(data = {}) {
  return axios.post('/expenses/new', data, getConfig());
}

export function fnEditExpense(estimateID, data = {}) {
  return axios.post(`/expenses/${estimateID}/edit`, data, getConfig());
}

export function fnGetInvoices(data = {}) {
  return axios.post('/invoices', data, getConfig());
}

export function fnAddInvoice(data = {}) {
  return axios.post('/invoices/new', data, getConfig());
}

export function fnGetInvoice(invoiceID) {
  return axios.get(`/invoices/${invoiceID}`, getConfig());
}

export function fnApproveInvoice(invoiceID) {
  return axios.post(`/invoices/${invoiceID}/approve`, {}, getConfig());
}

export function fnEditInvoice(invoiceID, data = {}) {
  return axios.post(`/invoices/${invoiceID}/edit`, data, getConfig());
}

export function fnGetLeads(data = {}) {
  return axios.post('/leads', data, getConfig());
}

export function fnAddLead(data = {}) {
  return axios.post('/leads/new', data, getConfig());
}

export function fnEditLead(leadID, data = {}) {
  return axios.post(`/leads/${leadID}/edit`, data, getConfig());
}

export function fnGetLeadLogs(leadID, data = {}) {
  return axios.post(`/leads/${leadID}/logs`, data, getConfig());
}

export function fnGetLeadRequirements(leadID, data = {}) {
  return axios.post(`/leads/${leadID}/requirements`, data, getConfig());
}

export function fnAddLeadRequirement(leadID, data = {}) {
  return axios.post(`/leads/${leadID}/requirements/new`, data, getConfig());
}

export function fnLogin(data = {}) {
  return axios.post('/login', data, getConfig());
}

export function fnGetProductServices(data = {}) {
  return axios.post('/product_services', data, getConfig());
}

export function fnAddProductService(data = {}) {
  return axios.post('/product_services/new', data, getConfig());
}

export function fnEditProductService(psID, data = {}) {
  return axios.post(`/product_services/${psID}/edit`, data, getConfig());
}

export function fnDeleteProductService(psID) {
  return axios.delete(`/product_services/${psID}/delete`, getConfig());
}

export function fnGetProjects(data = {}) {
  return axios.post('/projects', data, getConfig());
}

export function fnAddProject(data = {}) {
  return axios.post('/projects/new', data, getConfig());
}

export function fnGetProjectDocs(projectID, data = {}) {
  return axios.post(`/projects/${projectID}/docs`, data, getConfig());
}

export function fnAddProjectDoc(projectID, data = {}) {
  return axios.post(`/projects/${projectID}/docs/new`, data, getFileUploadConfig());
}

export function fnGetProjectDoc(projectID, documentID) {
  return axios.get(`/projects/${projectID}/docs/${documentID}/download`, getConfig());
}

export function fnDeleteProjectDoc(projectID, documentID) {
  return axios.delete(`/projects/${projectID}/docs/${documentID}/delete`, getConfig());
}

export function fnEditProjectDoc(projectID, documentID, data = {}) {
  return axios.post(`/projects/${projectID}/docs/${documentID}/edit`, data, getConfig());
}

export function fnEditProject(projectID, data = {}) {
  return axios.post(`/projects/${projectID}/edit`, data, getConfig());
}

export function fnGetProjectNotes(projectID, data = {}) {
  return axios.post(`/projects/${projectID}/notes`, data, getConfig());
}

export function fnAddProjectNote(projectID, data = {}) {
  return axios.post(`/projects/${projectID}/notes/new`, data, getConfig());
}

export function fnGetQuotes(data = {}) {
  return axios.post('/quotes', data, getConfig());
}

export function fnAddQuote(data = {}) {
  return axios.post('/quotes/new', data, getConfig());
}

export function fnGetQuote(quoteID) {
  return axios.get(`/quotes/${quoteID}`, getConfig());
}

export function fnApproveQuote(quoteID, data = {}) {
  return axios.post(`/quotes/${quoteID}/approve`, data, getConfig());
}

export function fnEditQuote(quoteID, data = {}) {
  return axios.post(`/quotes/${quoteID}/edit`, data, getConfig());
}

export function fnGetStaticOptions() {
  return axios.get('/static/all', getConfig());
}

export function fnGetTasks(data = {}) {
  return axios.post('/tasks', data, getConfig());
}

export function fnAddTask(data = {}) {
  return axios.post('/tasks/new', data, getConfig());
}

export function fnEditTask(taskID, data = {}) {
  return axios.post(`/tasks/${taskID}/edit`, data, getConfig());
}

export function fnGetTeamMembers(data = {}) {
  return axios.post('/team_members', data, getConfig());
}

export function fnAddTeamMember(data = {}) {
  return axios.post('/team_members/new', data, getConfig());
}

export function fnEditTeamMember(memberID, data = {}) {
  return axios.post(`/team_members/${memberID}/edit`, data, getConfig());
}

export function fnDeleteTeamMember(memberID) {
  return axios.delete(`/team_members/${memberID}/delete`, getConfig());
}

export function fnGetTimeTracking(data = {}) {
  return axios.post('/time_tracking', data, getConfig());
}

export function fnAddTimeTracking(data = {}) {
  return axios.post('/time_tracking/new', data, getConfig());
}

export function fnEditTimeTracking(data = {}) {
  return axios.post('/time_tracking/edit', data, getConfig());
}

export function fnDeleteTimeTracking(data = {}) {
  return axios.post(`/time_tracking/delete`, data, getConfig());
}
