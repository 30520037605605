import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {fnGetExpenses, fnAddExpense, fnEditExpense} from '../../api';
import {
  logError,
  displayMoney,
  responseDataExists,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader
} from '../../helpers';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';
import MissingInfoFooterNote from '../../components/MissingInfoFooterNote';

import './style.css';

export function Expenses() {
  const defaultExpense = () => {
    return {
      expense_id: null,
      name: '',
      description: '',
      cost: 0,
    }
  };

  const optionalFields = ['expense_id', 'description'];

  const [expenses, setExpenses] = useState([]);
  const [expense, setExpense] = useState(defaultExpense());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [sortables, setSortables] = useState({column: null, reset: false});
  const [search, setSearch] = useState('');
  const [errors, setErrors] = useState({});

  function handleOpenModal(expense) {
    if (!expense) {
      expense = defaultExpense();
      expense.name = search;
    } else {
      expense = cloneDeep(expense);
    }

    setExpense(expense);
    setModalDetails({...modalDetails, show: true, isEdit: expense.expense_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});

    // reset error state for all fields
    Object.keys(errors).forEach((k) => {
      errors[k] = false;
    });
    setErrors(errors);
  }

  function handleExpenseFieldUpdate(e) {
    const updatedExpense = {...expense};
    const name = e.target.name;
    let value = e.target.value;

    // reset error state for field
    errors[name] = false;
    setErrors(errors);

    switch (name) {
      case 'cost':
        if (typeof value === 'string') {
          value = parseFloat(value);
          if (isNaN(value)) {
            value = '';
          }
        }
        break;
      default:
    }

    updatedExpense[name] = value;
    setExpense(updatedExpense);
  }

  function validateSubmission(expense = {}) {
    if (expense) {
      const errors = {};
      let errored = false;

      Object.keys(defaultExpense()).forEach((k) => {
        if (!optionalFields.includes(k)) {
          errors[k] = !expense[k];
          errored = errored || errors[k];
        }
      });

      if (errored) {
        return errors;
      }
    }

    return null;
  }

  function getExpenses(filters = {search: ''}) {
    const data = {search: filters.search};
    setSearch(filters.search);

    fnGetExpenses(data).then((response) => {
      console.log('expenses:\n', response.data);
      if (responseDataExists(response)) {
        setExpenses(response.data.data || []);
      }
    }).catch(logError)
  }

  function addExpense() {
    if (expense) {
      const errors = validateSubmission(expense);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnAddExpense(expense).then((response) => {
        console.log('add expense:\n', response.data);
        if (response.data && response.data.data) {
          setExpenses([response.data.data, ...expenses]);
        }
        handleCloseModal();
      }).catch(logError)
    } else {
      handleCloseModal();
    }
  }

  function editExpense() {
    if (expense) {
      const errors = validateSubmission(expense);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnEditExpense(expense.expense_id, expense).then((response) => {
        console.log('edit expense:\n', response.data);
        if (response.data && response.data.data) {
          setExpenses(expenses.map(e => e.expense_id === expense.expense_id ? response.data.data : e));
        }
        handleCloseModal();
      }).catch(logError)
    } else {
      handleCloseModal();
    }
  }

  const delayGetExpenses = debounce((...args) => getExpenses(...args), 500);

  useEffect(() => {
    getExpenses();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Expenses</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetExpenses}
            searchPlaceholder="Search to enable add"
            hideDateRange
          />
          <button
            onClick={() => handleOpenModal()}
            disabled={!search}
            title={!search ? 'Use search to enable button' : ''}
          >
            +
          </button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Name</th>
            <th>Description</th>
            <SortableTableHeader
              list={expenses}
              getCompareElement={(e) => e ? e.cost : null}
              setList={setExpenses}
              reset={sortables.column !== 'cost' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('cost', sortables, setSortables)}
            >
              Cost
            </SortableTableHeader>
          </tr>
          </thead>
          <tbody>
          {expenses.map((expense, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(expense)}>{expense.name}</td>
              <td onClick={() => handleOpenModal(expense)}>{expense.description}</td>
              <td onClick={() => handleOpenModal(expense)}>{displayMoney(expense.cost)}</td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Expense</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleExpenseFieldUpdate}
              value={expense.name}
              className={errors.name ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              onChange={handleExpenseFieldUpdate}
              value={expense.description}
              className={errors.description ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="cost">Cost:</label>
            <input
              type="number"
              name="cost"
              onChange={handleExpenseFieldUpdate}
              value={expense.cost}
              className={errors.cost ? 'errored' : ''}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <MissingInfoFooterNote errored={Object.values(errors).includes(true)}/>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editExpense : addExpense}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Expenses;
