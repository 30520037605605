import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {fnGetTasks, fnAddTask, fnEditTask} from '../../api';
import {
  nowDateString,
  displayDate,
  displayTime,
  responseDataExists,
  logError,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader,
  soKeyTaskStatus,
} from '../../helpers';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import DatePicker from '../../components/DatePicker';
import MyTimePicker from '../../components/MyTimePicker';
import MissingInfoFooterNote from '../../components/MissingInfoFooterNote';

import './style.css';

export function Tasks() {
  const defaultTask = () => {
    return {
      task_id: null,
      name: '',
      deadline: nowDateString(),
      location: '',
      status: 'Not Started',
      ts_started: null,
      ts_completed: null,
    }
  };

  const optionalFields = ['task_id', 'ts_stared', 'ts_completed'];

  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState(defaultTask());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [sortables, setSortables] = useState({column: null, reset: false});
  const [errors, setErrors] = useState({});

  function handleOpenModal(task) {
    if (!task) {
      task = defaultTask();
    } else {
      task = cloneDeep(task);
    }

    setTask(task);
    setModalDetails({...modalDetails, show: true, isEdit: task.task_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});

    // reset error state for all fields
    Object.keys(errors).forEach((k) => {
      errors[k] = false;
    });
    setErrors(errors);
  }

  function handleTaskFieldUpdate(e) {
    const updatedTask = {...task};
    const deadline = new Date(updatedTask.deadline);
    let name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case 'deadline_date':
        name = 'deadline';
        value = new Date(value);
        value.setHours(deadline.getHours(), deadline.getMinutes());
        value = value.toJSON();
        break;
      case 'deadline_time':
        name = 'deadline';
        value = new Date(value);
        value.setFullYear(deadline.getFullYear(), deadline.getMonth(), deadline.getDate());
        value = value.toJSON();
        break;
    }

    // reset error state for field
    errors[name] = false;
    setErrors(errors);

    updatedTask[name] = value;
    setTask(updatedTask);
  }

  function validateSubmission(task = {}) {
    if (task) {
      const errors = {};
      let errored = false;

      Object.keys(defaultTask()).forEach((k) => {
        if (!optionalFields.includes(k)) {
          errors[k] = !task[k];
          errored = errored || errors[k];
        }
      });

      if (errored) {
        return errors;
      }
    }

    return null;
  }

  function getTasks(filters = {search: ''}) {
    const data = {search: filters.search};

    fnGetTasks(data).then((response) => {
      console.log('tasks:\n', response.data);
      if (responseDataExists(response)) {
        setTasks(response.data.data);
      }
    }).catch(logError)
  }

  function addTask() {
    if (task) {
      const errors = validateSubmission(task);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnAddTask(task).then((response) => {
        console.log('add task:\n', response.data);
        if (response.data && response.data.data) {
          setTasks([response.data.data, ...tasks]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editTask() {
    if (task) {
      const errors = validateSubmission(task);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnEditTask(task.task_id, task).then((response) => {
        console.log('edit task:\n', response.data);
        if (response.data && response.data.data) {
          setTasks(tasks.map((t) => t.task_id === task.task_id ? response.data.data : t));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  const delayGetTasks = debounce((...args) => getTasks(...args), 1000);

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Tasks</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetTasks}
            hideDateRange
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Status</th>
            <th>Name</th>
            <SortableTableHeader
              list={tasks}
              getCompareElement={(t) => t ? new Date(t.deadline).valueOf() : null}
              setList={setTasks}
              reset={sortables.column !== 'deadline' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('deadline', sortables, setSortables)}
            >
              Deadline
            </SortableTableHeader>
            <th>Location</th>
          </tr>
          </thead>
          <tbody>
          {tasks.map((task, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(task)}>{task.status}</td>
              <td onClick={() => handleOpenModal(task)}>{task.name}</td>
              <td onClick={() => handleOpenModal(task)}>
                {displayDate(task.deadline)} {displayTime(task.deadline, true)}
              </td>
              <td onClick={() => handleOpenModal(task)}>{task.location}</td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Task</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="status">Status:</label>
            <StaticOptionDropdown
              name="status"
              value={task.status}
              onSelect={handleTaskFieldUpdate}
              staticOptionKey={soKeyTaskStatus}
              className={errors.status ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleTaskFieldUpdate}
              value={task.name}
              className={errors.name ? 'errored' : ''}
            />
          </div>
          <div className="deadline-datetime">
            <label htmlFor="deadline">Deadline:</label>
            <DatePicker
              name="deadline_date"
              value={task.deadline || ''}
              onSelect={handleTaskFieldUpdate}
              className={errors.deadline ? 'errored' : ''}
            />
            <MyTimePicker
              name="deadline_time"
              value={task.deadline || ''}
              onSelect={handleTaskFieldUpdate}
              className={errors.deadline ? 'errored' : ''}
            />
          </div>

          <div>
            <label htmlFor="location">Location:</label>
            <input
              type="text"
              name="location"
              onChange={handleTaskFieldUpdate}
              value={task.location}
              className={errors.location ? 'errored' : ''}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <MissingInfoFooterNote errored={Object.values(errors).includes(true)}/>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editTask : addTask}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Tasks;
