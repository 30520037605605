import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import './style.css';

class YesNoDropdown extends React.Component {
  handleOnChange = (value) => {
    if (this.props.onSelect) {
      this.props.onSelect({target: {name: this.props.name || '', value: value}});
    }
  };

  render() {
    const {className, value} = this.props;

    return (
      <DropdownButton
        className={`yes-no-dropdown${className ? ` ${className}` : ''}`}
        name={this.props.name || ''}
        title={value === true ? 'Yes' : 'No'}
      >
        <Dropdown.Item
          as="button"
          key={0}
          onClick={() => this.handleOnChange(true)}
          active={value === true}
        >
          Yes
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          key={1}
          onClick={() => this.handleOnChange(false)}
          active={value === false}
        >
          No
        </Dropdown.Item>
      </DropdownButton>
    )
  }
}

YesNoDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

export default YesNoDropdown;