import React from 'react';
import PropTypes from 'prop-types';
import {Calendar} from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {displayDate, formatDateToLocale} from "../../helpers";

import './style.css';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({show: false});
    }
  };

  handleOnClick = () => {
    this.setState({show: !this.state.show});
  };

  handleOnChange = (date) => {
    if (this.props.onSelect) {
      this.props.onSelect({
        target: {
          name: this.props.name,
          value: date,
        }
      });
      this.setState({show: false});
    }
  };

  render() {
    const {disabledDay, disabled, value, className} = this.props;

    // set disabledDayFn
    let disabledDayFn = () => false;
    if (disabledDay) {
      disabledDayFn = disabledDay;
    }
    if (disabled) {
      disabledDayFn = () => true;
    }

    return (
      <div
        className={`date-picker-ctn${className ? ` ${className}` : ''}`}
        ref={this.state.show ? this.wrapperRef : null}
      >
        <label
          className={disabled ? 'disabled' : ''}
          onClick={this.handleOnClick}
        >
          {/* use &nbsp; to fix issue with alignment with predecessor tag*/}
          {value ? displayDate(value) : ''}&nbsp;
        </label>
        <div className={`date-picker${this.state.show ? '' : ' hidden'}`}>
          {this.props.showClear === true && value && !disabled ?
            <span onClick={() => this.handleOnChange(null)}>clear</span>
            :
            null
          }
          <Calendar
            onChange={this.handleOnChange}
            locale={locales['enUS']}
            date={value ? formatDateToLocale(value) : null}
            disabledDay={disabledDayFn}
          />
        </div>
      </div>
    )
  }

}

DatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  showClear: PropTypes.bool,
  disabledDay: PropTypes.func, // function(Date)
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default DatePicker;
