import React, {useState, useEffect, useContext} from 'react';
import {sortBy, cloneDeep} from "lodash";
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from "react-router-dom";
import {
  fnGetClients,
  fnGetInvoices,
  fnAddInvoice,
  fnEditInvoice,
  fnApproveInvoice,
  fnGetLeads,
  fnGetProductServices,
  fnGetProjects,
} from '../../api';
import {
  logError,
  displayDate,
  displayMoney,
  roundMoney,
  responseDataExists,
  displayClientOption,
  displayLeadOption,
  displayProductServiceOption,
  displayProjectOption,
  displayTime,
  soKeyInvoiceStatus,
  soKeyPropertyTypes,
  defaultPropertyType,
  StaticOptionsContext,
} from '../../helpers';
import DatePicker from '../../components/DatePicker';
import SearchDropdown from '../../components/SearchDropdown';
import Filters from '../../components/Filters';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import ConfirmationModal from "../../components/ConfirmationModal";
import {defaultQuote, quoteOptionalFields} from '../Quotes';
import MissingInfoFooterNote from '../../components/MissingInfoFooterNote';

import './style.css';

export const defaultInvoice = () => {
  return {
    ...defaultQuote(),
    invoice_id: null,
    project_id: null,
    invoice_num: '',
    invoice_status: null,
    ts_approved: null,
  }
};

export const invoiceOptionalFields = [...quoteOptionalFields, 'quote_type', 'invoice_id', 'invoice_num',
  'invoice_status', 'ts_approved'];

export function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState(defaultQuote());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false, propertyType: defaultPropertyType});
  const [clientOptions, setClientOptions] = useState({original: [], filtered: []});
  const [leadOptions, setLeadOptions] = useState([]);
  const [productServiceOptions, setProductServiceOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState({original: [], filtered: []});
  const [confirmModalDetails, setConfirmModalDetails] = useState({show: false, i: null});
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const staticOptions = useContext(StaticOptionsContext);

  function calculateSubtotalTax(invoice) {
    const {prices, quantities} = invoice;

    let subtotal = 0;
    prices.forEach((p, i) => {
      subtotal += p * quantities[i];
    });

    let tax = subtotal * 0.13;

    // round to 2 decimal places
    subtotal = roundMoney(subtotal);
    tax = roundMoney(tax);

    return {subtotal, tax};
  }

  function handleOpenModal(invoice) {
    if (!invoice) {
      invoice = defaultInvoice();
    } else {
      invoice = cloneDeep(invoice);
    }

    setInvoice(invoice);
    setModalDetails({...modalDetails, show: true, isEdit: invoice.invoice_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false, propertyType: defaultPropertyType});

    // reset error state for all fields
    Object.keys(errors).forEach((k) => {
      let value = false;
      if (Array.isArray(errors[k])) {
        value = errors[k].map(() => false);
      }
      errors[k] = value;
    });
    setErrors(errors);
  }

  function handleOpenConfirmModal(invoice, i) {
    if (invoice && typeof i === 'number') {
      setInvoice(invoice);
      setConfirmModalDetails({...confirmModalDetails, show: true, i});
    }
  }

  function handleCloseConfirmModal() {
    setConfirmModalDetails({...confirmModalDetails, show: false, i: null});
  }

  function handleInvoiceFieldUpdate(e) {
    const updatedInvoice = {...invoice};
    let name = e.target.name;
    let value = e.target.value;

    // parse for field names with subfields (ie object) or elements (ie arrays)
    // and extract the key or index
    let subKey = null;
    if (name.startsWith('for_details') || name.startsWith('at_details')) {
      // reset error state for field
      errors[name] = false;
      setErrors(errors);

      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = names[1];
      }
    } else if (name.startsWith('items') || name.startsWith('descriptions') ||
      name.startsWith('prices') || name.startsWith('quantities')) {
      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = parseInt(names[1], 10);

        if (typeof subKey === 'number' && errors[name]) {
          // reset error state for field
          errors[name][subKey] = false;
          setErrors(errors);
        }
      }
    } else {
      // reset error state for field
      errors[name] = false;
      setErrors(errors);
    }

    switch (name) {
      case 'client_id':
        // reset error state for lead if client set
        errors['lead_id'] = false;
      // eslint-disable-next-line
      case 'lead_id':
        // reset error state for client if lead set
        errors['client_id'] = false;

        if (value) {
          const forDetails = updatedInvoice.for_details;
          // default fill fields with client/lead data
          if (value.company && !forDetails.company) {
            forDetails.company = value.company;

            // reset error state for field since set
            errors['for_details.company'] = false;
          }
          if (value.name && !forDetails.name) {
            forDetails.name = value.name;

            // reset error state for field since set
            errors['for_details.name'] = false;
          }
          if (value.phone && !forDetails.phone) {
            forDetails.phone = value.phone;

            // reset error state for field since set
            errors['for_details.phone'] = false;
          }
          if (value.address && !forDetails.address) {
            forDetails.address = value.address;

            // reset error state for field since set
            errors['for_details.address'] = false;
          }

          // reassign value for updating
          value = value[name];

          // filter project options
          setProjectOptions({
            ...projectOptions,
            filtered: [...projectOptions.original].filter((o) => o[name] === value)
          })
        } else {
          // reset project options
          setProjectOptions({...projectOptions, filtered: [...projectOptions.original]});
        }
        break;
      case 'project_id':
        // reset error state for client and lead if project set
        errors['client_id'] = false;
        errors['lead_id'] = false;

        if (value) {
          const forDetails = updatedInvoice.for_details;
          // default fill fields with project data
          if (value.contact_name && !forDetails.name) {
            forDetails.name = value.contact_name;

            // reset error state for field since set
            errors['for_details.name'] = false;
          }
          if (value.contact_phone && !forDetails.phone) {
            forDetails.phone = value.contact_phone;

            // reset error state for field since set
            errors['for_details.phone'] = false;
          }
          if (value.address && !forDetails.address) {
            forDetails.address = value.address;

            // reset error state for field since set
            errors['for_details.address'] = false;
          }

          // default client/lead id
          updatedInvoice.client_id = value.client_id;
          updatedInvoice.lead_id = value.lead_id;

          // reassign value for updating
          value = value[name];
        }

        break;
      case 'date':
      case 'date_valid':
        if (value) {
          value = value.toJSON();
        }
        break;
      case 'for_details':
      case 'at_details':
        // update object fields
        const details = updatedInvoice[name];
        if (subKey) {
          details[subKey] = value;
        }

        // reassign value for updating
        value = details;
        break;
      case 'items':
      case 'descriptions':
      case 'prices':
      case 'quantities':
        // update array elements
        const list = updatedInvoice[name];
        if (typeof subKey === 'number') {
          // make sure quantity values are integers
          if (name === 'quantities') {
            switch (typeof value) {
              case 'number':
                value = Math.floor(value);
                break;
              case 'string':
                value = parseInt(value, 10);
                if (isNaN(value)) {
                  value = '';
                }
                break;
              default:
                console.log(`setting quantity with ${typeof value}`)
            }
          } else if (name === 'prices') {
            if (typeof value === 'string') {
              value = parseFloat(value);
              if (isNaN(value)) {
                value = '';
              }
            }
          }


          list[subKey] = value;
        }

        // reassign value for updating
        value = list;

        // recalculate subtotal and tax
        if (['prices', 'quantities'].includes(name)) {
          const updatedSubtotalTax = calculateSubtotalTax(updatedInvoice);
          updatedInvoice.subtotal = updatedSubtotalTax.subtotal;
          updatedInvoice.tax = updatedSubtotalTax.tax;
        }
        break;
      case 'subtotal':
      case 'tax':
        switch (typeof value) {
          case 'string':
            value = parseFloat(value);
            if (isNaN(value)) {
              value = '';
              break;
            }
          // eslint-disable-next-line
          case 'number':
            value = roundMoney(value);
            break;
          default:
            console.log(`setting subtotal/tax with ${typeof value}`)
        }
        break;
      default:
    }

    updatedInvoice[name] = value;
    setInvoice(updatedInvoice);
  }

  function handleAddItem(e) {
    const updatedInvoice = {...invoice};
    const {items, descriptions, prices, quantities} = updatedInvoice;
    const value = e.target.value;

    if (value) {
      if (value.name || value.prices !== null) {
        if (value.name) {
          items.unshift(value.name);
        } else {
          items.unshift('');
        }

        if (value.description) {
          descriptions.unshift(value.description);
        } else {
          descriptions.unshift('');
        }

        if (value.prices) {
          prices.unshift(value.prices[modalDetails.propertyType]);
        } else {
          prices.unshift(0.0);
        }

        quantities.unshift(1);

        // update errors
        if (errors.prices) {
          errors.prices.unshift(false);
        }
        if (errors.quantities) {
          errors.quantities.unshift(false);
        }
        setErrors(errors);
      }

      // recalculate subtotal and tax
      const updatedSubtotalTax = calculateSubtotalTax(updatedInvoice);
      updatedInvoice.subtotal = updatedSubtotalTax.subtotal;
      updatedInvoice.tax = updatedSubtotalTax.tax;

      setInvoice(updatedInvoice);
    }
  }

  function handleDeleteItem(i) {
    const updatedInvoice = {...invoice};
    const {items, descriptions, prices, quantities} = updatedInvoice;

    if (typeof i === 'number') {
      items.splice(i, 1);
      descriptions.splice(i, 1);
      prices.splice(i, 1);
      quantities.splice(i, 1);

      const updatedSubtotalTax = calculateSubtotalTax(updatedInvoice);
      updatedInvoice.subtotal = updatedSubtotalTax.subtotal;
      updatedInvoice.tax = updatedSubtotalTax.tax;

      setInvoice(updatedInvoice);

      // update errors
      if (errors.prices) {
        errors.prices.splice(i, 1);
      }
      if (errors.quantities) {
        errors.quantities.splice(i, 1);
      }
      setErrors(errors);
    }
  }

  function handlePropertyTypeChange(e) {
    setModalDetails({...modalDetails, propertyType: e.target.value});
  }

  function validateSubmission(invoice = {}, ignoreFields = []) {
    if (invoice) {
      const errors = {};
      let errored = false;

      // ignore client_id and/or lead_id if the other set or project is set
      if (invoice.project_id) {
        ignoreFields.push('client_id', 'lead_id');
      } else if (invoice.client_id) {
        ignoreFields.push('lead_id');
      } else if (invoice.lead_id) {
        ignoreFields.push('client_id');
      }

      Object.keys(defaultInvoice()).forEach((k) => {
        if (!invoiceOptionalFields.includes(k) && !ignoreFields.includes(k)) {
          if (['for_details', 'at_details'].includes(k)) {
            Object.keys(invoice[k]).forEach((k2) => {
              const key = `${k}.${k2}`;
              if (!invoiceOptionalFields.includes(key)) {
                errors[key] = !invoice[k][k2];
                errored = errored || errors[key];
              }
            })
          } else if (['subtotal', 'tax'].includes(k)) {
            errors[k] = !(typeof invoice[k] === 'number');
            errored = errored || errors[k];
          } else if (['prices', 'quantities'].includes(k)) {
            invoice[k].forEach((v, i) => {
              if (!invoiceOptionalFields.includes(k)) {
                if (!errors[k]) {
                  // initialize if not set
                  errors[k] = Array.apply(() => false, {length: invoice[k].length});
                }
                errors[k][i] = !(typeof invoice[k][i] === 'number');
                errored = errored || errors[k][i];
              }
            })
          } else {
            errors[k] = !invoice[k];
            errored = errored || errors[k];
          }
        }
      });

      if (errored) {
        return errors;
      }
    }

    return null;
  }

  function getInvoices(filters = {clientID: null}) {
    const data = {client_id: filters.clientID};

    fnGetInvoices(data).then((response) => {
      console.log('invoices:\n', response.data);
      if (responseDataExists(response)) {
        setInvoices(response.data.data || []);
      }
    }).catch(logError)
  }

  function addInvoice() {
    if (invoice) {
      const errors = validateSubmission(invoice);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnAddInvoice(invoice).then((response) => {
        console.log('add invoice:\n', response.data);
        if (response.data && response.data.data) {
          setInvoices([response.data.data, ...invoices]);
        }
        handleCloseModal();
      }).catch(logError)
    } else {
      handleCloseModal();
    }
  }

  function approveInvoice() {
    if (invoice) {
      fnApproveInvoice(invoice.invoice_id).then((response) => {
        console.log('approved invoice:\n', invoice);

        let i = confirmModalDetails.i;
        if (typeof i !== 'number') {
          i = invoices.findIndex((o) => o.invoice_id === invoice.invoice_id);
        }
        if (i >= 0) {
          invoices[i].ts_approved = responseDataExists(response) ? response.data.data : new Date().toJSON();
          setInvoices(invoices);
        }

        handleCloseConfirmModal();
      }).catch(logError)
    } else {
      handleCloseConfirmModal();
    }
  }

  function editInvoice() {
    if (invoice) {
      const errors = validateSubmission(invoice, ['project_id', 'client_id', 'lead_id']);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnEditInvoice(invoice.invoice_id, invoice).then((response) => {
        console.log('edit invoice:\n', response.data);
        if (response.data && response.data.data) {
          setInvoices(invoices.map((i) => i.invoice_id === invoice.invoice_id ? response.data.data : i));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function getClientOptions(search = '', clientIDs = {}) {
    fnGetClients({search: search}).then((response) => {
      console.log('client options:\n', response.data);
      if (responseDataExists(response)) {
        const original =
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayClientOption(o);
              return o;
            }),
            (o) => o.displayOption
          );
        setClientOptions({original, filtered: original.filter((o) => clientIDs[o.client_id])});
      }
    }).catch(logError)
  }

  function getLeadOptions(search = '', leadIDs = {}) {
    fnGetLeads({search: search}).then((response) => {
      console.log('lead options:\n', response.data);
      if (responseDataExists(response)) {
        setLeadOptions(
          sortBy(
            (response.data.data || []).filter((o) => leadIDs[o.lead_id]).map((o) => {
              o.displayOption = displayLeadOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getProductServiceOptions(search = '') {
    fnGetProductServices({search: search}).then((response) => {
      console.log('product service options:\n', response.data);
      if (responseDataExists(response)) {
        setProductServiceOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = {};
              (staticOptions[soKeyPropertyTypes] || []).forEach((pt) => {
                o.displayOption[pt] = displayProductServiceOption(o, pt);
              });
              return o;
            }),
            (o) => o.name
          )
        );
      }
    }).catch(logError)
  }

  function getProjectOptions(search = '') {
    fnGetProjects({search: search}).then((response) => {
      console.log('project options:\n', response.data);
      if (responseDataExists(response)) {
        const clientIDs = {};
        const leadIDs = {};
        const original = sortBy(
          (response.data.data || []).map((o) => {
            // generate mapping for clients and leads
            if (o.client_id) {
              clientIDs[o.client_id] = true;
            }
            if (o.lead_id) {
              leadIDs[o.lead_id] = true;
            }

            o.displayOption = displayProjectOption(o);
            return o;
          }),
          (o) => o.displayOption
        );
        setProjectOptions({original, filtered: [...original]});

        // get client/lead options
        getClientOptions('', clientIDs);
        getLeadOptions('', leadIDs);
      }
    }).catch(logError)
  }

  useEffect(() => {
    if (!(location && location.state && location.state.clientID)) {
      getInvoices();
    }
    getProjectOptions();
  }, []);

  useEffect(() => {
    getProductServiceOptions();
  }, [staticOptions]);

  return (
    <div>
      <div className="page-header">
        <h1>Invoices</h1>
        <div className="right">
          <Filters
            hideSearch
            hideDateRange
            showClient
            clientOptions={clientOptions.original}
            onChangeClient={getInvoices}
            initialClientID={(location && location.state && location.state.clientID) || null}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Invoice #</th>
            <th>Date</th>
            <th>Due Date</th>
            <th>Company</th>
            <th>At Address</th>
            <th>At Town/Province</th>
            <th>Total</th>
            <th>Status</th>
            <th>Statement</th>
            <th>Approve</th>
          </tr>
          </thead>
          <tbody>
          {invoices.map((invoice, i) => (
            <tr
              className={`table-row${invoice.ts_approved ? ' approved' : ''}`}
              key={i}
            >
              <td onClick={() => handleOpenModal(invoice)}>{invoice.invoice_num}</td>
              <td onClick={() => handleOpenModal(invoice)}>{displayDate(invoice.date)}</td>
              <td onClick={() => handleOpenModal(invoice)}>{displayDate(invoice.date_valid)}</td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.for_details.name}</td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.at_details.address}</td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.at_details.town_province}</td>
              <td onClick={() => handleOpenModal(invoice)}>
                {displayMoney(invoice.subtotal + invoice.tax)}
              </td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.invoice_status}</td>
              <td>
                <Link
                  to={`/invoices/${invoice.invoice_id}/view`}
                  state={{data: invoice}}
                >
                  View
                </Link>
              </td>
              <td>
                {!invoice.ts_approved ?
                  <button onClick={() => handleOpenConfirmModal(invoice, i)}>Confirm</button>
                  :
                  <>
                    {`${displayDate(invoice.ts_approved)} ${displayTime(invoice.ts_approved, true)}`}
                  </>
                }
              </td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Invoice</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <>
              <div>
                <label htmlFor="client_id">Client:</label>
                <SearchDropdown
                  name="client_id"
                  value={invoice.client_id}
                  onSelect={handleInvoiceFieldUpdate}
                  options={clientOptions.filtered}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.client_id}
                  showClear
                  disabled={invoice.lead_id !== null || invoice.project_id !== null}
                  resetValue
                  className={errors.client_id ? 'errored' : ''}
                />
              </div>
              <div>
                <label htmlFor="lead_id">Lead:</label>
                <SearchDropdown
                  name="lead_id"
                  value={invoice.lead_id}
                  onSelect={handleInvoiceFieldUpdate}
                  options={leadOptions}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.lead_id}
                  showClear
                  disabled={invoice.client_id !== null || invoice.project_id !== null}
                  resetValue
                  className={errors.lead_id ? 'errored' : ''}
                />
              </div>
              <div>
                <label htmlFor="project_id">Project:</label>
                <SearchDropdown
                  name="project_id"
                  value={invoice.project_id}
                  onSelect={handleInvoiceFieldUpdate}
                  options={projectOptions.filtered}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.project_id}
                  showClear
                  className={errors.project_id ? 'errored' : ''}
                />
              </div>
            </>
            :
            null
          }
          <div>
            <label htmlFor="date">Date:</label>
            <DatePicker
              name="date"
              value={invoice.date}
              onSelect={handleInvoiceFieldUpdate}
              className={errors.date ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="date_valid">Date Valid:</label>
            <DatePicker
              name="date_valid"
              value={invoice.date_valid}
              onSelect={handleInvoiceFieldUpdate}
              className={errors.date_valid ? 'errored' : ''}
            />
          </div>
          <div className="sub-questions">
            <h5>Prepared For:</h5>
            <div>
              <label htmlFor="for_details.company">Company:</label>
              <input
                type="text"
                name="for_details.company"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.company}
                className={errors[`for_details.company`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="for_details.name">Name:</label>
              <input
                type="text"
                name="for_details.name"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.name}
                className={errors[`for_details.name`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="for_details.phone">Phone:</label>
              <input
                type="text"
                name="for_details.phone"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.phone}
                className={errors[`for_details.phone`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="for_details.address">Address:</label>
              <input
                type="text"
                name="for_details.address"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.address}
                className={errors[`for_details.address`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="for_details.town_province">Town/Province:</label>
              <input
                type="text"
                name="for_details.town_province"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.town_province}
                className={errors[`for_details.town_province`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="for_details.postal_code">Postal Code:</label>
              <input
                type="text"
                name="for_details.postal_code"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.postal_code}
                className={errors[`for_details.postal_code`] ? 'errored' : ''}
              />
            </div>
          </div>
          <div className="sub-questions">
            <h5>Service Address:</h5>
            <div>
              <label htmlFor="at_details.name">Name:</label>
              <input
                type="text"
                name="at_details.name"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.name}
                className={errors[`at_details.name`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="at_details.address">Address:</label>
              <input
                type="text"
                name="at_details.address"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.address}
                className={errors[`at_details.address`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="at_details.town_province">Town/Province:</label>
              <input
                type="text"
                name="at_details.town_province"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.town_province}
                className={errors[`at_details.town_province`] ? 'errored' : ''}
              />
            </div>
            <div>
              <label htmlFor="at_details.postal_code">Postal Code:</label>
              <input
                type="text"
                name="at_details.postal_code"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.postal_code}
                className={errors[`at_details.postal_code`] ? 'errored' : ''}
              />
            </div>
          </div>
          <div className="sub-questions">
            <h5>Items:</h5>
            <div>
              <StaticOptionDropdown
                name="propertyType"
                value={modalDetails.propertyType}
                onSelect={handlePropertyTypeChange}
                staticOptionKey={soKeyPropertyTypes}
              />
              <SearchDropdown
                value={null}
                onSelect={handleAddItem}
                options={productServiceOptions}
                fnDisplayOption={(option) => option.displayOption[modalDetails.propertyType]}
                placeholder="Product/Service"
                dismissSelected={true}
              />
            </div>
            <table>
              <thead>
              {invoice.items.length > 0 ?
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Delete</th>
                </tr>
                :
                null
              }
              </thead>
              <tbody>
              {invoice.items.map((item, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="text"
                      name={`items.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={item}
                      className={errors.items && errors.items[i] ? 'errored' : ''}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`descriptions.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={invoice.descriptions[i]}
                      className={errors.descriptions && errors.descriptions[i] ? 'errored' : ''}
                    />
                  </td>
                  <td>
                    <input
                      className={`input-number${errors.prices && errors.prices[i] ? ' errored' : ''}`}
                      type="number"
                      name={`prices.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={invoice.prices[i]}
                    />
                  </td>
                  <td>
                    <input
                      className={`input-number${errors.quantities && errors.quantities[i] ? ' errored' : ''}`}
                      type="number"
                      name={`quantities.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={invoice.quantities[i]}
                    />
                  </td>
                  <td>
                    <span
                      className="btn-clear"
                      onClick={() => handleDeleteItem(i)}
                    >
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div>
            <label htmlFor="subtotal">Subtotal:</label>
            <input
              type="number"
              name="subtotal"
              onChange={handleInvoiceFieldUpdate}
              value={invoice.subtotal}
              className={errors.subtotal ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="tax">Taxes:</label>
            <input
              type="number"
              name="tax"
              onChange={handleInvoiceFieldUpdate}
              value={invoice.tax}
              className={errors.tax ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="total">Total:</label>
            <input
              type="number"
              name="total"
              value={roundMoney(invoice.subtotal + invoice.tax)}
              disabled
            />
          </div>
          <div>
            <label htmlFor="notes">Notes:</label>
            <textarea
              name="notes"
              onChange={handleInvoiceFieldUpdate}
              value={invoice.notes}
              className={errors.notes ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="invoice_status">Status:</label>
            <StaticOptionDropdown
              name="invoice_status"
              value={invoice.invoice_status}
              onSelect={handleInvoiceFieldUpdate}
              staticOptionKey={soKeyInvoiceStatus}
              hideDefault
              className={errors.invoice_status ? 'errored' : ''}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <MissingInfoFooterNote
            errored={
              Object.values(errors).reduce((p, c) => {
                if (Array.isArray(p)) {
                  return c || p.includes(true);
                }
                return c || p;
              }, false)
            }
          />
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editInvoice : addInvoice}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={confirmModalDetails.show}
        title="Approve Invoice?"
        messages={[
          'Please confirm you want to approve invoice for:',
          `Company: ${invoice.for_details.company}`,
          `Date: ${displayDate(invoice.date)}`,
          `Amount: ${displayMoney(invoice.subtotal + invoice.tax)}`,
        ]}
        onOK={approveInvoice}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default Invoices;
