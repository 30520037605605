import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {StaticOptionsContext, displayTitle} from '../../helpers';

import './style.css';

function StaticOptionDropdown(
  {
    name = '',
    value = '',
    onSelect = null,
    staticOptionKey = '',
    hideDefault = false,
    canClear = false,
    className = '',
  }) {
  const staticOptions = useContext(StaticOptionsContext);

  function handleOnChange(value) {
    if (onSelect) {
      onSelect({target: {name: name || '', value: value}});
    }
  }

  // default value
  value = value || (hideDefault ? '' : staticOptions[staticOptionKey][0]) || '';

  return (
    <div className={`static-option-dropdown${className ? ` ${className}` : ''}`}>
      <DropdownButton
        name={name}
        title={value}>
        {(staticOptions[staticOptionKey] || []).map((option, index) => (
          <Dropdown.Item
            as="button"
            key={index}
            onClick={() => handleOnChange(option)}
            active={value === option}
          >
            {displayTitle(option)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {canClear && value ?
        <span
          className="btn-clear"
          onClick={() => handleOnChange(null)}
        >
          ❌
        </span>
        :
        null
      }
    </div>
  )
}

StaticOptionDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  staticOptionKey: PropTypes.string,
  hideDefault: PropTypes.bool,
  canClear: PropTypes.bool,
  className: PropTypes.string,
};

export default StaticOptionDropdown;