import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function MissingInfoFooterNote({errored = false}) {

  return (
    <span
      className="errored-text"
      hidden={!errored}
    >
      *fill in missing info before saving
    </span>
)
}

MissingInfoFooterNote.propTypes = {
  errored: PropTypes.bool,
};

export default MissingInfoFooterNote;