import React, {useState, useEffect, useContext} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {fnGetProductServices, fnAddProductService, fnEditProductService, fnDeleteProductService} from '../../api';
import {
  logError,
  displayMoney,
  responseDataExists,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader,
  soKeyPropertyTypes,
  StaticOptionsContext,
  displayTitle,
} from '../../helpers';
import ConfirmationModal from '../../components/ConfirmationModal';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';
import MissingInfoFooterNote from '../../components/MissingInfoFooterNote';

import './style.css';

export function ProductServices() {
  const staticOptions = useContext(StaticOptionsContext);

  const [defaultProductService, setDefaultProductService] = useState({});
  const [propertyTypes, setPropertyTypes] = useState([]);

  useEffect(() => {
    const obj = {
      ps_id: null,
      name: '',
      description: '',
      prices: {},
    };

    const defaultPropertyTypes = [];
    (staticOptions[soKeyPropertyTypes] || []).forEach((pt) => {
      defaultPropertyTypes.push(pt);
      obj.prices[pt] = 0.0;
    });

    setDefaultProductService(obj);
    setPropertyTypes(defaultPropertyTypes);
  }, [staticOptions]);

  const optionalFields = ['ps_id', 'description'];

  const [productServices, setProductServices] = useState([]);
  const [productService, setProductService] = useState(defaultProductService);
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sortables, setSortables] = useState({column: null, reset: false});
  const [errors, setErrors] = useState({});

  function handleOpenModal(productService) {
    if (!productService) {
      productService = defaultProductService;
    } else {
      productService = cloneDeep(productService);
    }

    setProductService(productService);
    setModalDetails({...modalDetails, show: true, isEdit: productService.ps_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});

    // reset error state for all fields
    Object.keys(errors).forEach((k) => {
      errors[k] = false;
    });
    setErrors(errors);
  }

  function handleOpenConfirmModal(productService) {
    if (productService) {
      setProductService(productService);
      setShowConfirmModal(true);
    }
  }

  function handleCloseConfirmModal() {
    setShowConfirmModal(false);
  }

  function handleProductServiceFieldUpdate(e) {
    const updatedProductService = {...productService};
    let name = e.target.name;
    let value = e.target.value;

    // reset error state for field
    errors[name] = false;
    setErrors(errors);

    // parse for field names with subfields (ie object) or elements (ie arrays)
    // and extract the key or index
    let subKey = null;
    if (name.startsWith('prices')) {
      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = names[1];
      }
    }

    switch (name) {
      case 'prices':
        // update object fields
        const prices = updatedProductService[name];
        if (subKey) {
          if (typeof value === 'string') {
            value = parseFloat(value);
            if (isNaN(value)) {
              value = '';
            }
          }
          prices[subKey] = value;
        }

        // reassign value for updating
        value = prices;
        break;
      default:
    }

    updatedProductService[name] = value;
    setProductService(updatedProductService);
  }

  function validateSubmission(ps = {}) {
    if (ps) {
      const errors = {};
      let errored = false;

      Object.keys(defaultProductService).forEach((k) => {
        if (!optionalFields.includes(k)) {
          if (k === 'prices') {
            Object.keys(ps[k]).forEach((k2) => {
              const key = `${k}.${k2}`;
              if (!optionalFields.includes(key)) {
                errors[key] = !(typeof ps[k][k2] === 'number') ;
                errored = errored || errors[key];
              }
            })
          } else {
            errors[k] = !ps[k];
            errored = errored || errors[k];
          }
        }
      });

      if (errored) {
        return errors;
      }
    }

    return null;
  }

  function getProductServices(filters = {search: ''}) {
    const data = {search: filters.search};

    fnGetProductServices(data).then((response) => {
      console.log('product services:\n', response.data);
      if (responseDataExists(response)) {
        setProductServices(response.data.data || []);
      }
    }).catch(logError)
  }

  function addProductService() {
    if (productService) {
      const errors = validateSubmission(productService);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnAddProductService(productService).then((response) => {
        console.log('add product service:\n', response.data);
        if (response.data && response.data.data) {
          setProductServices([response.data.data, ...productServices]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editProductService() {
    if (productService) {
      const errors = validateSubmission(productService);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnEditProductService(productService.ps_id, productService).then((response) => {
        console.log('edit produce service:\n', response.data);
        if (response.data && response.data.data) {
          setProductServices(productServices.map((ps) => ps.ps_id === productService.ps_id ? response.data.data : ps));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function deleteProductService(productService) {
    if (productService && productService.ps_id) {
      fnDeleteProductService(productService.ps_id).then(() => {
        console.log('delete product service:\n', productService);
        setProductServices(productServices.filter((ps) => ps.ps_id !== productService.ps_id));
        handleCloseConfirmModal()
      }).catch(logError);
    } else {
      handleCloseConfirmModal()
    }
  }

  const delayGetProductServices = debounce((...args) => getProductServices(...args), 1000);

  useEffect(() => {
    getProductServices();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Products & Services</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetProductServices}
            hideDateRange
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Name</th>
            <th>Description</th>
            {propertyTypes.map((pt, i) => (
              <SortableTableHeader
                list={productServices}
                getCompareElement={(ps) => ps ? ps.prices[pt] : null}
                setList={setProductServices}
                reset={sortables.column !== `${pt}_price` && sortables.reset}
                resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
                onClick={() => handleOnClickSortableTableHeader(`${pt}_price`, sortables, setSortables)}
                key={i}
              >
                {displayTitle(pt)} Price
              </SortableTableHeader>
            ))
            }
            <th>Delete</th>
          </tr>
          </thead>
          <tbody>
          {productServices.map((productService, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(productService)}>{productService.name}</td>
              <td onClick={() => handleOpenModal(productService)}>{productService.description}</td>
              {propertyTypes.map((pt, i) => (
                <td
                  onClick={() => handleOpenModal(productService)}
                  key={i}
                >
                  {displayMoney(productService.prices[pt])}
                </td>
              ))
              }
              <td><span onClick={() => handleOpenConfirmModal(productService)}>❌</span></td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Product & Service</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleProductServiceFieldUpdate}
              value={productService.name}
              className={errors.name ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              onChange={handleProductServiceFieldUpdate}
              value={productService.description}
              className={errors.description ? 'errored' : ''}
            />
          </div>
          {propertyTypes.map((pt, i) => (
            <div key={i}>
              <label htmlFor={`${pt}_prices`}>{displayTitle(pt)} Price:</label>
              <input
                type="number"
                name={`prices.${pt}`}
                onChange={handleProductServiceFieldUpdate}
                value={productService.prices && productService.prices[pt]}
                className={errors[`prices.${pt}`] ? 'errored' : ''}
              />
            </div>
          ))
          }

        </Modal.Body>

        <Modal.Footer>
          <MissingInfoFooterNote errored={Object.values(errors).includes(true)}/>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editProductService : addProductService}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmModal}
        title="Delete Product & Service?"
        messages={[`Please confirm you want delete ${productService.name}?`]}
        onOK={() => deleteProductService(productService)}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default ProductServices;
