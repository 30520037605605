import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import {loadStateWithKey} from './LocalStorage';
import App from './containers/App';
import Clients from './containers/Clients';
import Estimates from './containers/Estimates';
import Expenses from './containers/Expenses';
import Invoice from './containers/Invoice';
import Invoices from './containers/Invoices';
import Leads from './containers/Leads';
import Login from './containers/Login';
import Notes, {
  NoteTypeEstimateNote,
  NoteTypeLeadRequirement,
  NoteTypeProjectNote,
} from './containers/Notes';
import ProductServices from './containers/ProductServices';
import LogHistory, {
  LogHistoryTypeClient,
  LogHistoryTypeLead,
} from './containers/LogHistory';
import Projects from './containers/Projects';
import ProjectDocuments from './containers/ProjectDocuments';
import Quotes from './containers/Quotes';
import Tasks from './containers/Tasks';
import TeamMembers from './containers/TeamMembers';
import TimeTracker from './containers/TimeTracker';
import {
  routeParamClientID,
  routeParamEstimateID,
  routeParamInvoiceID,
  routeParamLeadID,
  routeParamProjectID,
  routeParamQuoteID,
} from './helpers';

// MainNavRouter ordering determines MainNav ordering, only first level used
export const MainNavRouter = [
  {
    path: "/clients",
    element: <Clients/>,
    display: 'CLIENTS',
  },
  {
    path: "/leads",
    element: <Leads/>,
    display: 'LEADS',
  },
  {
    path: "/estimates",
    element: <Estimates/>,
    display: 'ESTIMATE TRACKER',
  },
  {
    path: "/projects",
    element: <Projects/>,
    display: 'PROJECT TRACKER',
  },
  {
    path: "/quotes",
    element: <Quotes/>,
    display: 'QUOTING',
  },
  {
    path: "/invoices",
    element: <Invoices/>,
    display: 'INVOICES',
  },
  {
    path: "/product_services",
    element: <ProductServices/>,
    display: 'PRODUCT AND SERVICES',
  },
  {
    path: "/team_members",
    element: <TeamMembers/>,
    display: 'TEAM MEMBERS',
  },
  {
    path: "/time_tracker",
    element: <TimeTracker/>,
    display: 'TIME TRACKER',
  },
  {
    path: "/tasks",
    element: <Tasks/>,
    display: 'TASKS',
  },
  {
    path: "/expenses",
    element: <Expenses/>,
    display: 'EXPENSES',
  }
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    loader: () => {
      // check if logged in
      return loadStateWithKey('X-Api-Key') && loadStateWithKey('X-Api-Key') !== '';
    },
    children: [
      {/* default page */
        element: <Clients/>,
        index: true,
      },
      ...MainNavRouter,
      {
        path: `/clients/:${routeParamClientID}/project_history`,
        element: <LogHistory/>,
        loader: () => {
          return LogHistoryTypeClient;
        },
      },
      {
        path: `/estimates/:${routeParamEstimateID}/notes`,
        element: <Notes/>,
        loader: () => {
          return NoteTypeEstimateNote;
        },
      },
      {
        path: `/invoices/:${routeParamInvoiceID}/view`,
        element: <Invoice/>,
      },
      {
        path: `/leads/:${routeParamLeadID}/requirements`,
        element: <Notes/>,
        loader: () => {
          return NoteTypeLeadRequirement;
        },
      },
      {
        path: `/leads/:${routeParamLeadID}/project_history`,
        element: <LogHistory/>,
        loader: () => {
          return LogHistoryTypeLead;
        },
      },
      {
        path: `/projects/:${routeParamProjectID}/notes`,
        element: <Notes/>,
        loader: () => {
          return NoteTypeProjectNote;
        },
      },
      {
        path: `/projects/:${routeParamProjectID}/documents`,
        element: <ProjectDocuments/>,
      },
      {
        path: `/quotes/:${routeParamQuoteID}/view`,
        element: <Invoice/>,
      },
    ],
  },
  {
    path: "/login",
    element: <Login/>
  }
]);