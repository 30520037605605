import React, {useState, useEffect} from 'react';
import {debounce, sortBy, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from "react-router-dom";
import {fnGetEstimates, fnAddEstimate, fnEditEstimate, fnGetLeads, fnGetTeamMembers} from '../../api';
import {
  soKeyEstimateStatus,
  displayDate,
  displayDuration,
  displayTime,
  displayYesNo,
  logError,
  nowDateString,
  nowLocaleDateString,
  getFullLocation,
  responseDataExists,
  displayLeadOption,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader,
  displayTeamMemberOption,
} from '../../helpers';
import DatePicker from "../../components/DatePicker";
import MyTimePicker from '../../components/MyTimePicker';
import YesNoDropdown from '../../components/YesNoDropdown';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import SearchDropdown from '../../components/SearchDropdown';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';
import ConfirmationModal from '../../components/ConfirmationModal';
import MissingInfoFooterNote from '../../components/MissingInfoFooterNote';

import './style.css';

export function Estimates() {
  const pageName = 'Estimate Tracker';
  const statusOrderMap = {
    Waiting: 0,
    Abandoned: 1,
    Won: 2,
  };

  const defaultEstimate = () => {
    return {
      estimate_id: null,
      lead_id: null,
      estimate_num: '',
      name: '',
      company: '',
      address: '',
      city: '',
      description: '',
      estimate_ts: null,
      estimator_id: null,
      estimator: '',
      sent: false,
      status: 'Waiting',
      ts_track_start: null,
      ts_track_end: null,
      temp_estimate_ts: nowLocaleDateString(), // allow updating without disabling fields
    }
  };

  const optionalFields = ['estimate_id', 'estimate_num', 'description', 'estimator', 'sent',
    'ts_track_start', 'ts_track_end', 'temp_estimate_ts'];

  const [estimates, setEstimates] = useState([]);
  const [estimate, setEstimate] = useState(defaultEstimate());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [leadOptions, setLeadOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [sortables, setSortables] = useState({column: null, reset: false});
  const [confirmModalDetails, setConfirmModalDetails] = useState({show: false, title: '', messages: ['']});
  const [errors, setErrors] = useState({});
  const location = useLocation();

  function handleOpenModal(estimate) {
    if (!estimate) {
      estimate = defaultEstimate();
    } else {
      estimate = cloneDeep(estimate);
    }
    if (estimate.estimate_ts) {
      estimate.temp_estimate_ts = estimate.estimate_ts;
    }

    setEstimate(estimate);
    setModalDetails({...modalDetails, show: true, isEdit: estimate.estimate_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});

    // reset error state for all fields
    Object.keys(errors).forEach((k) => {
      errors[k] = false;
    });
    setErrors(errors);
  }

  function handleOpenConfirmModal(estimate) {
    if (estimate) {
      setEstimate(estimate);
      const isStart = !estimate.ts_track_start;

      setConfirmModalDetails({
        ...confirmModalDetails,
        show: true,
        title: isStart ? 'Start Timer?' : 'Stop Timer?',
        messages: [
          `Please confirm you want to ${isStart ? 'start' : 'stop'} timer for:`,
          `Estimate #: ${estimate.estimate_num}`,
          `Company: ${estimate.company}`,
          `Date: ${displayDate(estimate.estimate_ts)}`]
      });
    }
  }

  function handleCloseConfirmModal() {
    setConfirmModalDetails({...confirmModalDetails, show: false});
  }

  function handleOkConfirmationModal() {
    if (estimate && (!estimate.ts_track_start || !estimate.ts_track_end)) {
      if (!estimate.ts_track_start) {
        estimate.ts_track_start = nowDateString();
      } else {
        estimate.ts_track_end = nowDateString();
      }
      setEstimate(estimate);
      editEstimate();
    } else {
      handleCloseConfirmModal();
    }
  }

  function handleEstimateFieldUpdate(e) {
    const updatedEstimate = {...estimate};
    let name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case 'lead_id':
        if (value) {
          // default fill fields with lead data
          if (value.name && !updatedEstimate.name) {
            updatedEstimate.name = value.name;
          }
          if (value.company && !updatedEstimate.company) {
            updatedEstimate.company = value.company;
          }
          if (value.address && !updatedEstimate.address) {
            updatedEstimate.address = value.address;
          }

          // reassign value for updating
          value = value.lead_id;
        }
        break;
      case 'temp_estimate_ts.date':
        name = 'temp_estimate_ts';
        if (value) {
          const ts = new Date(updatedEstimate.temp_estimate_ts);
          // reassign time
          value.setHours(ts.getHours(), ts.getMinutes(), ts.getSeconds(), ts.getMilliseconds());
          value = value.toJSON();
        }
        break;
      case 'temp_estimate_ts.time':
        name = 'temp_estimate_ts';
        if (value) {
          const ts = new Date(updatedEstimate.temp_estimate_ts);
          // reassign date
          value.setFullYear(ts.getFullYear(), ts.getMonth(), ts.getDate());
          value = value.toJSON();
        }
        break;
      case 'estimator_id':
        if (value) {
          value = value.member_id;
        }
        break;
      default:
    }

    // reset error state for field
    errors[name] = false;
    setErrors(errors);

    updatedEstimate[name] = value;
    setEstimate(updatedEstimate);
  }

  function validateSubmission(estimate = {}) {
    if (estimate) {
      const errors = {};
      let errored = false;

      Object.keys(defaultEstimate()).forEach((k) => {
        if (!optionalFields.includes(k)) {
          errors[k] = !estimate[k];
          errored = errored || errors[k];
        }
      });

      if (errored) {
        return errors;
      }
    }

    return null;
  }

  function sanitizeEstimate(estimate = {}) {
    const sanitizedEstimate = {...estimate};
    // copy temp_estimate_ts to estimate_ts
    sanitizedEstimate.estimate_ts = sanitizedEstimate.temp_estimate_ts;

    return sanitizedEstimate;
  }

  function getEstimates(filters = {search: ''}) {
    const data = {search: filters.search};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    fnGetEstimates(data).then((response) => {
      console.log('estimates:\n', response.data);
      if (responseDataExists(response)) {
        setEstimates(response.data.data || []);
      }
    }).catch(logError);
  }

  function addEstimate() {
    if (estimate) {
      // sanitize estimate to set estimate_ts
      const sanitizedEstimate = sanitizeEstimate(estimate);
      const errors = validateSubmission(sanitizedEstimate);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnAddEstimate(sanitizedEstimate).then((response) => {
        console.log('add estimate:\n', response.data);
        if (response.data && response.data.data) {
          setEstimates([response.data.data, ...estimates]);
        }
        handleCloseModal();
      }).catch(logError);
    } else {
      handleCloseModal();
    }
  }

  function editEstimate() {
    if (estimate) {
      // sanitize estimate to set estimate_ts
      const sanitizedEstimate = sanitizeEstimate(estimate);
      const errors = validateSubmission(sanitizedEstimate);
      if (errors) {
        setErrors(errors);
        return;
      }

      fnEditEstimate(estimate.estimate_id, sanitizedEstimate).then((response) => {
        console.log('edit estimate:\n', response.data);
        if (response.data && response.data.data) {
          setEstimates(estimates.map((e) => e.estimate_id === estimate.estimate_id ? response.data.data : e));
        }
        handleCloseModal();
        handleCloseConfirmModal();
      }).catch(logError);
    } else {
      handleCloseModal();
      handleCloseConfirmModal();
    }
  }

  function getLeadOptions(search = '') {
    fnGetLeads({search: search}).then((response) => {
      console.log('lead options:\n', response.data);
      if (responseDataExists(response)) {
        setLeadOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayLeadOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getTeamMemberOptions(search = '') {
    fnGetTeamMembers({search: search, get_deleted: true}).then((response) => {
      console.log('team member options:\n', response.data);
      if (responseDataExists(response)) {
        setMemberOptions(
          sortBy(
            (response.data.data || []).filter((m) => m.position === 'Estimator').map((m) => {
              m.displayOption = displayTeamMemberOption(m);
              return m;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  const delayGetEstimates = debounce((...args) => getEstimates(...args), 1000);

  useEffect(() => {
    getEstimates();
    getLeadOptions();
    getTeamMemberOptions();
  }, []);

  function hasEstimateTsPassed(estimate = {}) {
    if (estimate) {
      return estimate.estimate_ts ? new Date().valueOf() > new Date(estimate.estimate_ts).valueOf() : false;
    }
  }

  return (
    <div>
      <div className="page-header">
        <h1>{pageName}</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetEstimates}
            onChangeDateRange={getEstimates}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Estimate #</th>
            <th>Name</th>
            <th>Company</th>
            <th>Address</th>
            <th>City</th>
            <th>Description</th>
            <th>Estimate Date</th>
            <th>Estimate Time</th>
            <th>Estimator</th>
            <th>Time</th>
            <SortableTableHeader
              list={estimates}
              getCompareElement={(e) => e ? e.sent === false : null}
              setList={setEstimates}
              reset={sortables.column !== 'sent' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('sent', sortables, setSortables)}
            >
              Sent
            </SortableTableHeader>
            <SortableTableHeader
              list={estimates}
              getCompareElement={(e) => e ? statusOrderMap[e.status] : null}
              setList={setEstimates}
              reset={sortables.column !== 'status' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('status', sortables, setSortables)}
            >
              Status
            </SortableTableHeader>
            <th>Notes</th>
          </tr>
          </thead>
          <tbody>
          {estimates.map((estimate, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(estimate)}>{estimate.estimate_num}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.name}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.company}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.address}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.city}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.description}</td>
              <td onClick={() => handleOpenModal(estimate)}>{displayDate(estimate.estimate_ts)}</td>
              <td onClick={() => handleOpenModal(estimate)}>{displayTime(estimate.estimate_ts, true)}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.estimator}</td>
              <td>
                {estimate.ts_track_start && estimate.ts_track_end ?
                  <>
                    {displayDuration(estimate.ts_track_start, estimate.ts_track_end)} Hrs
                  </>
                  :
                  <button
                    className={estimate.ts_track_start ? 'red-btn' : 'grn-btn'}
                    onClick={() => handleOpenConfirmModal(estimate)}
                  >
                    {estimate.ts_track_start ? 'Stop' : 'Start'}
                  </button>
                }
              </td>
              <td onClick={() => handleOpenModal(estimate)}>{displayYesNo(estimate.sent === true)}</td>
              <td onClick={() => handleOpenModal(estimate)}>{estimate.status}</td>
              <td>
                <Link
                  to={`/estimates/${estimate.estimate_id}/notes`}
                  state={{back: getFullLocation(location), backName: pageName}}
                >
                  notes
                </Link>
              </td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} {pageName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <div>
              <label htmlFor="lead_id">Lead:</label>
              <SearchDropdown
                name="lead_id"
                value={estimate.lead_id}
                onSelect={handleEstimateFieldUpdate}
                options={leadOptions}
                fnDisplayOption={(option) => option.displayOption}
                fnValueMatchOption={(value, option) => value === option.lead_id}
                className={errors.lead_id ? 'errored' : ''}
              />
            </div>
            :
            null
          }
          <div>
            <label htmlFor="estimate_num">Estimate:</label>
            <input
              type="text"
              name="estimate_num"
              onChange={handleEstimateFieldUpdate}
              value={estimate.estimate_num}
              placeholder={!modalDetails.isEdit ? '001-001' : ''}
              className={errors.estimate_num ? 'errored' : ''}
            />
            {!modalDetails.isEdit ?
              <span> *Leave blank to auto generate</span>
              :
              null
            }
          </div>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleEstimateFieldUpdate}
              value={estimate.name}
              className={errors.name ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="company">Company:</label>
            <input
              type="text"
              name="company"
              onChange={handleEstimateFieldUpdate}
              value={estimate.company}
              className={errors.company ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              name="address"
              onChange={handleEstimateFieldUpdate}
              value={estimate.address}
              className={errors.address ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="city">City:</label>
            <input
              type="text"
              name="city"
              onChange={handleEstimateFieldUpdate}
              value={estimate.city}
              className={errors.city ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <input
              type="text"
              name="description"
              onChange={handleEstimateFieldUpdate}
              value={estimate.description}
              className={errors.description ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="temp_estimate_date">Estimate Date:</label>
            <DatePicker
              name="temp_estimate_ts.date"
              value={estimate.temp_estimate_ts || ''}
              onSelect={handleEstimateFieldUpdate}
              disabled={hasEstimateTsPassed(estimate)}
              className={errors.temp_estimate_ts ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="temp_estimate_time">Estimate Time:</label>
            <MyTimePicker
              name="temp_estimate_ts.time"
              value={estimate.temp_estimate_ts || ''}
              onSelect={handleEstimateFieldUpdate}
              disabled={hasEstimateTsPassed(estimate)}
              className={errors.temp_estimate_ts ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="estimator_id">Estimator:</label>
            <SearchDropdown
              name="estimator_id"
              value={estimate.estimator_id}
              onSelect={handleEstimateFieldUpdate}
              options={hasEstimateTsPassed(estimate) ? memberOptions : memberOptions.filter((m) => !m.is_deleted)}
              fnDisplayOption={(option) => option.displayOption}
              fnValueMatchOption={(value, option) => value === option.member_id}
              disabled={hasEstimateTsPassed(estimate)}
              className={errors.estimator_id ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="sent">Sent:</label>
            <YesNoDropdown
              name="sent"
              onSelect={handleEstimateFieldUpdate}
              value={estimate.sent}
              className={errors.sent ? 'errored' : ''}
            />
          </div>
          <div>
            <label htmlFor="status">Status:</label>
            <StaticOptionDropdown
              name="status"
              value={estimate.status}
              onSelect={handleEstimateFieldUpdate}
              staticOptionKey={soKeyEstimateStatus}
              className={errors.status ? 'errored' : ''}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <MissingInfoFooterNote errored={Object.values(errors).includes(true)}/>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editEstimate : addEstimate}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={confirmModalDetails.show}
        title={confirmModalDetails.title}
        messages={confirmModalDetails.messages}
        onOK={handleOkConfirmationModal}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default Estimates;
